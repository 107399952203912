import React from "react";
import CountUp from "react-countup";
import styled from "styled-components";
import { FaRocket, FaLightbulb, FaHeart } from "react-icons/fa";

const Container = styled.section`
    background: linear-gradient(90deg, #fff8f0, #ffe0b2);
    padding: 60px 20px;
    color: #5d4037;
`;

const Title = styled.h2`
    text-align: center;
    font-size: 34px;
    margin-bottom: 40px;
    color: #bf360c;
    font-weight: bold;
`;

const ContentContainer = styled.div`
    max-width: 1200px;
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 30px;
`;

const Card = styled.div`
    background: #fff;
    padding: 30px 20px;
    border-radius: 12px;
    text-align: center;
    box-shadow: 0 6px 10px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease, box-shadow 0.3s ease;

    &:hover {
        transform: translateY(-8px);
        box-shadow: 0 8px 16px rgba(0, 0, 0, 0.15);
    }
`;

const IconWrapper = styled.div`
    font-size: 40px;
    margin-bottom: 10px;
    color: #bf360c;
`;

const Number = styled.div`
    font-size: 32px;
    font-weight: bold;
    margin-bottom: 5px;
    color: #bf360c;
`;

const Label = styled.div`
    font-size: 18px;
    color: #5d4037;
`;

const WhyChooseUs = () => {
  return (
    <Container>
      <Title>¿Por qué elegirnos?</Title>
      <ContentContainer>
        <Card>
          <IconWrapper>
            <FaRocket />
          </IconWrapper>
          <Number>
            <CountUp end={12345} duration={3} separator="," />
          </Number>
          <Label>Proyectos exitosos</Label>
        </Card>
        <Card>
          <IconWrapper>
            <FaLightbulb />
          </IconWrapper>
          <Number>
            <CountUp end={87} duration={3} separator="," />
          </Number>
          <Label>Innovación implementada</Label>
        </Card>
        <Card>
          <IconWrapper>
            <FaHeart />
          </IconWrapper>
          <Number>
            <CountUp end={1234} duration={3} separator="," />
          </Number>
          <Label>Clientes felices</Label>
        </Card>
      </ContentContainer>
    </Container>
  );
};

export default WhyChooseUs;
