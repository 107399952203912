import React from "react";
import styled from "styled-components";

const PartnersContainer = styled.section`
    width: 100%;
    padding: 60px 20px;
    background-color: #fff8f0;
    box-sizing: border-box;
`;

const Title = styled.h2`
    text-align: center;
    font-size: 32px;
    margin-bottom: 40px;
    color: #bf360c;
    font-weight: bold;
`;

const PartnersGrid = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: 30px;
    justify-content: center;
`;

const PartnerLogo = styled.img`
    max-width: 150px;
    transition: filter 0.3s ease, transform 0.3s ease;
    filter: grayscale(100%);

    &:hover {
        filter: grayscale(0%);
        transform: scale(1.05);
    }
`;

const partnersData = [
  { logo: "partn1.jpg", alt: "Socio 1" },
  { logo: "partn2.jpg", alt: "Socio 2" },
  { logo: "partn3.jpg", alt: "Socio 3" },
  { logo: "partn4.jpg", alt: "Socio 4" },
  { logo: "partn5.jpg", alt: "Socio 5" }
];

const Partners = () => {
  return (
    <PartnersContainer>
      <Title>Nuestros Socios</Title>
      <PartnersGrid>
        {partnersData.map((partner, index) => (
          <PartnerLogo key={index} src={partner.logo} alt={partner.alt} />
        ))}
      </PartnersGrid>
    </PartnersContainer>
  );
};

export default Partners;
