import React, { useState } from "react";
import styled from "styled-components";

const FAQContainer = styled.section`
    width: 100%;
    padding: 40px 20px;
    background-color: #fff8f0;
    box-sizing: border-box;
`;

const InnerContainer = styled.div`
    max-width: 800px;
    margin: 40px auto;
`;

const FAQItem = styled.div`
    border-bottom: 1px solid #ffe0b2;
    margin-bottom: 10px;
`;

const Question = styled.h3`
    margin: 0;
    padding: 15px;
    font-size: 18px;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #ffcc80;
    color: #5d4037;
    border-radius: 4px;
`;

const Answer = styled.div`
    padding: 15px;
    font-size: 16px;
    color: #5d4037;
    display: ${({ isOpen }) => (isOpen ? "block" : "none")};
    background-color: #fff;
    border-radius: 0 0 4px 4px;
`;

const BloqueFAQ = () => {
  const faqItems = [
    {
      question: "¿Qué es Infinity y cómo funciona?",
      answer:
        "Infinity es una plataforma innovadora que integra inteligencia artificial con soluciones empresariales, permitiendo optimizar procesos y fomentar la creatividad en el trabajo diario."
    },
    {
      question: "¿Cuáles son los beneficios de unirse a Infinity?",
      answer:
        "Al unirte a Infinity, tendrás acceso a tecnología de última generación, asesoría personalizada y formarás parte de una comunidad que impulsa el crecimiento profesional y personal."
    },
    {
      question: "¿Cómo puedo registrarme en Infinity?",
      answer:
        "El proceso de registro es sencillo: solo completa el formulario en nuestro sitio web y sigue las instrucciones para activar tu cuenta de forma inmediata."
    },
    {
      question: "¿Qué tipos de proyectos desarrolla Infinity?",
      answer:
        "Desarrollamos proyectos enfocados en inteligencia artificial, automatización y transformación digital, diseñados para revolucionar la manera en que operan las empresas."
    },
    {
      question: "¿Necesito experiencia previa para participar?",
      answer:
        "No es necesario tener experiencia previa. Infinity está diseñado para ser accesible tanto para principiantes como para profesionales, ofreciendo recursos y apoyo en cada etapa."
    },
    {
      question: "¿Dónde puedo obtener más información?",
      answer:
        "Puedes encontrar más detalles en nuestro sitio web o comunicarte con nuestro equipo de soporte, quienes estarán encantados de ayudarte con cualquier consulta."
    }
  ];

  const [openItems, setOpenItems] = useState(Array(faqItems.length).fill(false));

  const toggleItem = (index) => {
    setOpenItems((prev) => {
      const newOpenItems = [...prev];
      newOpenItems[index] = !newOpenItems[index];
      return newOpenItems;
    });
  };

  return (
    <FAQContainer>
      <InnerContainer>
        {faqItems.map((item, index) => (
          <FAQItem key={index}>
            <Question onClick={() => toggleItem(index)}>
              {item.question} <span>{openItems[index] ? "–" : "+"}</span>
            </Question>
            <Answer isOpen={openItems[index]}>{item.answer}</Answer>
          </FAQItem>
        ))}
      </InnerContainer>
    </FAQContainer>
  );
};

export default BloqueFAQ;
