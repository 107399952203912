import React from "react";
import styled from "styled-components";

const Wrapper = styled.section`
  width: 100%;
  padding: 80px 20px;
  background: linear-gradient(135deg, #ffcc80, #ffe0b2);
  box-sizing: border-box;
`;

const Container = styled.div`
  max-width: 1000px;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 40px;
`;

const ImageContainer = styled.div`
  flex: 1;
  min-width: 300px;
  text-align: center;
`;

const Image = styled.img`
    max-width: 100%;
    border-radius: 12px;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.15);
`;

const TextContainer = styled.div`
    flex: 1;
    min-width: 300px;
`;

const Title = styled.h1`
    font-size: 36px;
    color: #d84315;
    margin-bottom: 20px;
    font-weight: bold;
`;

const Paragraph = styled.p`
    font-size: 18px;
    color: #5d4037;
    line-height: 1.8;
    margin-bottom: 20px;
`;

const About = () => {
  return (
    <Wrapper>
      <Container>
        <ImageContainer>
          <Image src="/infinity-about.jpg" alt="Acerca de Infinity Tech España" />
        </ImageContainer>
        <TextContainer>
          <Title>Acerca de Infinity Tech España</Title>
          <Paragraph>
            En Infinity Tech España nos dedicamos a transformar el futuro mediante soluciones digitales innovadoras. Desde nuestra sede en Madrid, lideramos la transformación digital y el desarrollo de inteligencia artificial para optimizar procesos y potenciar el crecimiento.
          </Paragraph>
          <Paragraph>
            Nuestro equipo de profesionales trabaja con pasión y creatividad, anticipándose a los desafíos del mañana y ofreciendo herramientas tecnológicas que abren nuevas fronteras para las empresas. ¡Únete a nosotros y descubre un mundo lleno de posibilidades!
          </Paragraph>
        </TextContainer>
      </Container>
    </Wrapper>
  );
};

export default About;
