import React from "react";
import styled from "styled-components";

const Wrapper = styled.section`
    width: 100%;
    padding: 60px 20px;
    background: linear-gradient(135deg, #fff8f0, #ffe0b2);
    box-sizing: border-box;
`;

const ContentBlock = styled.div`
    max-width: 800px;
    margin: 40px auto;
    padding: 40px 30px;
    background-color: #fff;
    border-radius: 12px;
    box-shadow: 0 8px 12px rgba(0, 0, 0, 0.1);
`;

const Title = styled.h1`
    text-align: center;
    font-size: 36px;
    margin-bottom: 20px;
    color: #bf360c;
    font-weight: bold;
`;

const Content = styled.div`
    font-size: 18px;
    line-height: 1.7;
    color: #5d4037;

    p {
        margin-bottom: 20px;
    }

    a {
        color: #bf360c;
        text-decoration: none;
        font-weight: 500;
    }

    a:hover {
        text-decoration: underline;
    }
`;

const CookiePolicy = () => {
  return (
    <Wrapper>
      <ContentBlock>
        <Title>Política de Cookies</Title>
        <Content>
          <p>
            En Infinity Tech España, utilizamos cookies para personalizar y mejorar tu experiencia en nuestro sitio. Las cookies son pequeños archivos que se guardan en tu dispositivo al visitar nuestra web, permitiéndonos recordar tus preferencias y optimizar el rendimiento.
          </p>
          <p>
            Empleamos cookies propias y de terceros para analizar el uso del sitio, adaptar el contenido y mostrar publicidad relevante. Al continuar navegando, aceptas el uso de cookies según se describe en esta política.
          </p>
          <p>
            Puedes configurar tu navegador para rechazar o eliminar cookies, aunque esto puede afectar la funcionalidad y personalización de nuestro sitio.
          </p>
          <p>
            Para más detalles, consulta nuestra{" "}
            <a href="/terms" target="_blank" rel="noopener noreferrer">
              Política de Privacidad
            </a>.
          </p>
        </Content>
      </ContentBlock>
    </Wrapper>
  );
};

export default CookiePolicy;
