import React from "react";
import styled from "styled-components";

const TeamContainer = styled.section`
    width: 100%;
    padding: 60px 20px;
    background: #fff8f0;
    box-sizing: border-box;
`;

const Title = styled.h2`
    text-align: center;
    font-size: 34px;
    margin-bottom: 40px;
    color: #bf360c;
    font-weight: bold;
`;

const TeamGrid = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: 30px;
    justify-content: center;
`;

const TeamCard = styled.div`
    background: #fff;
    border-radius: 12px;
    overflow: hidden;
    max-width: 280px;
    flex: 1;
    min-width: 240px;
    box-shadow: 0 6px 10px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease, box-shadow 0.3s ease;

    &:hover {
        transform: translateY(-8px);
        box-shadow: 0 8px 16px rgba(0, 0, 0, 0.15);
    }
`;

const TeamPhoto = styled.img`
    width: 100%;
    height: 250px;
    object-fit: cover;
`;

const TeamInfo = styled.div`
    padding: 20px;
    text-align: center;
`;

const TeamName = styled.h3`
    font-size: 22px;
    margin-bottom: 8px;
    color: #bf360c;
`;

const TeamRole = styled.p`
    font-size: 16px;
    color: #6d4c41;
    margin-bottom: 12px;
    font-weight: 500;
`;

const TeamDescription = styled.p`
    font-size: 14px;
    color: #5d4037;
    line-height: 1.5;
`;

const teamData = [
  {
    photo: "team1.jpg",
    name: "Ana García",
    role: "CEO",
    description:
      "Líder comprometida con la innovación y la transformación digital, dedicada a inspirar y guiar a nuestro equipo hacia nuevos horizontes."
  },
  {
    photo: "team2.jpg",
    name: "Carlos López",
    role: "CTO",
    description:
      "Apasionado por la tecnología, especializado en desarrollar soluciones de software que mejoran la eficiencia y fomentan el crecimiento."
  },
  {
    photo: "team3.jpg",
    name: "María Fernández",
    role: "Directora de Marketing",
    description:
      "Experta en comunicación y estrategia de mercado, enfocada en impulsar el crecimiento de la marca con creatividad y empatía."
  },
  {
    photo: "team4.jpg",
    name: "José Martínez",
    role: "Jefe de Proyectos",
    description:
      "Profesional orientado a la gestión colaborativa de proyectos, comprometido con la calidad y la consecución de resultados sostenibles."
  }
];

const Team = () => {
  return (
    <TeamContainer>
      <Title>Nuestro Equipo</Title>
      <TeamGrid>
        {teamData.map((miembro, index) => (
          <TeamCard key={index}>
            <TeamPhoto src={miembro.photo} alt={miembro.name} />
            <TeamInfo>
              <TeamName>{miembro.name}</TeamName>
              <TeamRole>{miembro.role}</TeamRole>
              <TeamDescription>{miembro.description}</TeamDescription>
            </TeamInfo>
          </TeamCard>
        ))}
      </TeamGrid>
    </TeamContainer>
  );
};

export default Team;
