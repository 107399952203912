import React from "react";
import styled from "styled-components";

const BlogContainer = styled.section`
    width: 100%;
    padding: 60px 20px;
    background-color: #fff8f0; /* теплий кремовий фон */
    box-sizing: border-box;
`;

const Title = styled.h2`
    text-align: center;
    font-size: 34px;
    margin-bottom: 40px;
    color: #bf360c; /* насичений оранжевий */
    font-weight: bold;
`;

const BlogGrid = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: 30px;
    justify-content: center;
`;

const BlogCard = styled.div`
    background-color: #fff;
    border-radius: 12px;
    overflow: hidden;
    max-width: 320px;
    flex: 1;
    min-width: 280px;
    box-shadow: 0 6px 10px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease, box-shadow 0.3s ease;

    &:hover {
        transform: translateY(-8px);
        box-shadow: 0 8px 16px rgba(0, 0, 0, 0.15);
    }
`;

const CardImage = styled.img`
    width: 100%;
    height: 200px;
    object-fit: cover;
`;

const CardContent = styled.div`
    padding: 20px;
`;

const CardTitle = styled.h3`
    font-size: 22px;
    margin-bottom: 10px;
    color: #bf360c;
    font-weight: 600;
`;

const CardDate = styled.p`
    font-size: 14px;
    color: #6d4c41;
    margin-bottom: 10px;
`;

const CardExcerpt = styled.p`
    font-size: 16px;
    color: #5d4037;
    line-height: 1.5;
    margin-bottom: 15px;
`;

const ReadMoreButton = styled.a`
    display: inline-block;
    padding: 10px 20px;
    background-color: #bf360c;
    color: #fff;
    text-decoration: none;
    border-radius: 4px;
    font-size: 16px;
    transition: background-color 0.3s ease;

    &:hover {
        background-color: #d84315;
    }
`;

const blogPosts = [
  {
    image: "blog1.jpg",
    title: "Lanzamiento de la nueva plataforma digital",
    date: "15 de abril de 2025",
    excerpt:
      "Infinity Tech España presenta su nueva plataforma digital, diseñada para revolucionar la experiencia del usuario y optimizar procesos empresariales.",
    link: "#"
  },
  {
    image: "blog2.jpg",
    title: "Innovaciones en inteligencia artificial",
    date: "10 de abril de 2025",
    excerpt:
      "Descubre cómo nuestras últimas innovaciones en inteligencia artificial están transformando la manera en que las empresas operan en la era digital.",
    link: "#"
  },
  {
    image: "blog3.jpg",
    title: "Alianzas estratégicas para el crecimiento",
    date: "05 de abril de 2025",
    excerpt:
      "Hemos establecido nuevas alianzas estratégicas que potenciarán nuestro crecimiento y expandirán las oportunidades de negocio en el sector tecnológico.",
    link: "#"
  }
];

const Blog = () => {
  return (
    <BlogContainer>
      <Title>Últimas Noticias</Title>
      <BlogGrid>
        {blogPosts.map((post, index) => (
          <BlogCard key={index}>
            <CardImage src={post.image} alt={post.title} />
            <CardContent>
              <CardTitle>{post.title}</CardTitle>
              <CardDate>{post.date}</CardDate>
              <CardExcerpt>{post.excerpt}</CardExcerpt>

            </CardContent>
          </BlogCard>
        ))}
      </BlogGrid>
    </BlogContainer>
  );
};

export default Blog;
