import React from "react";
import styled from "styled-components";
import Header from "./Components/Header";
import Footer from "./Components/Footer";

const Container = styled.div`
    max-width: 800px;
    margin: 120px auto 40px; /* враховуємо висоту Header */
    padding: 40px 30px;
    background-color: #fff8f0; /* теплий кремовий фон */
    border-radius: 12px;
    box-shadow: 0 6px 10px rgba(0, 0, 0, 0.1);
`;

const Title = styled.h1`
    text-align: center;
    font-size: 36px;
    margin-bottom: 30px;
    color: #bf360c; /* насичений оранжевий акцент */
    font-weight: bold;
`;

const Content = styled.div`
    font-size: 18px;
    line-height: 1.7;
    color: #5d4037; /* теплий темно-коричневий */

    p {
        margin-bottom: 20px;
    }

    strong {
        color: #bf360c;
    }
`;

const TermsAndConditions = () => {
  return (
    <>

      <Container>
        <Title>Términos y Condiciones</Title>
        <Content>
          <p>
            Bienvenido a Infinity Tech España. Al acceder a nuestro sitio, aceptas estos términos y condiciones, que pueden ser actualizados en cualquier momento sin previo aviso. Te recomendamos revisarlos periódicamente para estar siempre informado.
          </p>
          <p>
            <strong>1. Uso del Sitio:</strong> El usuario se compromete a utilizar el sitio de forma ética y conforme a la legislación vigente. Queda prohibido utilizar el sitio para actividades que puedan comprometer la seguridad o integridad de la información.
          </p>
          <p>
            <strong>2. Derechos de Propiedad:</strong> Todo el contenido, incluyendo textos, imágenes, logotipos y software, es propiedad exclusiva de Infinity Tech España y está protegido por las leyes de propiedad intelectual.
          </p>
          <p>
            <strong>3. Limitación de Responsabilidad:</strong> Infinity Tech España no se responsabiliza por daños directos, indirectos o consecuentes derivados del uso o la imposibilidad de uso del sitio.
          </p>
          <p>
            <strong>4. Actualizaciones y Modificaciones:</strong> Nos reservamos el derecho de modificar, actualizar o eliminar cualquier parte de estos términos en cualquier momento, sin necesidad de previo aviso. El uso continuado del sitio implica la aceptación de dichas modificaciones.
          </p>
          <p>
            <strong>5. Legislación y Jurisdicción:</strong> Estos términos se regirán e interpretarán de acuerdo con la legislación española. En caso de disputa, ambas partes se someterán a la jurisdicción exclusiva de los tribunales de Madrid.
          </p>
          <p>
            Si tienes alguna duda o necesitas más información, no dudes en contactarnos.
          </p>
        </Content>
      </Container>

    </>
  );
};

export default TermsAndConditions;
