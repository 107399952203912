// App.js
import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Header from './Components/Header'; // убедитесь, что путь правильный
import About from './About';
import Contact from './Contact';
import InfinityComponent from './Components/InfinityComponent';
import PrivilegiosInfinity from './Components/PrivilegesComponent';
import BloqueProyectoStartup from './Components/BloqueProyectoStartup';
import FAQ from './Components/FAQ';
import BloqueUbicacion from './Components/BloqueUbicacion';
import Footer from './Components/Footer';
import CookieBanner from './CookieBanner';
import TermsAndConditions from './TermsAndConditions';
import CookiePolicy from './CookiePolicy';
import WhyChooseUs from './Components/WhyChooseUs';
import Portfolio from './Components/Portfolio';
import Team from './Components/Team';
import Partners from './Components/Partners';
import Testimonials from './Components/Testimonials';
import Newsletter from './Components/Newsletter';
import Blog from './Components/Blog';

const App = () => {
  return (
    <Router>
      <div className="app-container min-h-screen bg-gray-100 text-gray-800">
        <Header />
        <Routes>
          <Route
            path="/"
            element={
              <>
                <Blog/>
                <InfinityComponent />
                <WhyChooseUs/>
                <BloqueProyectoStartup/>
                <Team/>
                <Testimonials/>
                <FAQ/>
                <Partners/>
              </>
            }
          />
          <Route path="/about" element={<About />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/terms" element={<TermsAndConditions />} />
          <Route path="/cookies" element={<CookiePolicy />} />
        </Routes>
        <Footer />
        <CookieBanner/>
      </div>
    </Router>
  );
};

export default App;
