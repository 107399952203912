import React, { useState } from 'react';
import styled from 'styled-components';

const NewsletterContainer = styled.section`
    width: 100%;
    padding: 60px 20px;
    background: linear-gradient(90deg, #f57c00, #ff9800);
    box-sizing: border-box;
    text-align: center;
    color: #fff;
`;

const NewsletterTitle = styled.h2`
    font-size: 32px;
    margin-bottom: 20px;
`;

const NewsletterText = styled.p`
    font-size: 18px;
    margin-bottom: 30px;
`;

const Form = styled.form`
    display: flex;
    flex-direction: column;
    align-items: center;
`;

const Input = styled.input`
    padding: 12px 20px;
    font-size: 16px;
    border: none;
    border-radius: 4px;
    margin-bottom: 20px;
    width: 300px;
`;

const Button = styled.button`
    padding: 12px 30px;
    font-size: 18px;
    background-color: #fff;
    color: #f57c00;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s;

    &:hover {
        background-color: #fdd835;
    }
`;

const Newsletter = () => {
  const [email, setEmail] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    // Реалізація логіки підписки (наприклад, API-запит)
    alert(`Suscripción realizada: ${email}`);
    setEmail('');
  };

  return (
    <NewsletterContainer>
      <NewsletterTitle>Suscríbete a nuestras noticias</NewsletterTitle>
      <NewsletterText>
        Mantente al tanto de las últimas novedades y ofertas exclusivas de Infinity Tech España.
      </NewsletterText>
      <Form onSubmit={handleSubmit}>
        <Input
          type="email"
          placeholder="Introduce tu email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
        />
        <Button type="submit">Suscribirse</Button>
      </Form>
    </NewsletterContainer>
  );
};

export default Newsletter;
