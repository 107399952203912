import React from "react";
import styled from "styled-components";
import BloqueUbicacion from "./Components/BloqueUbicacion";

const Wrapper = styled.section`
    width: 100%;
    padding: 80px 20px;
    background: linear-gradient(135deg, #fff8f0, #ffe0b2);
    box-sizing: border-box;
`;

const Container = styled.div`
    max-width: 1000px;
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    gap: 40px;
    align-items: flex-start;
`;

const InfoColumn = styled.div`
    flex: 1;
    min-width: 300px;
    padding: 20px;
    background: #fff;
    border-radius: 12px;
    box-shadow: 0 6px 10px rgba(0, 0, 0, 0.1);
`;

const FormColumn = styled.div`
    flex: 1;
    min-width: 300px;
    padding: 20px;
    background: #fff;
    border-radius: 12px;
    box-shadow: 0 6px 10px rgba(0, 0, 0, 0.1);
`;

const Title = styled.h1`
    font-size: 36px;
    color: #d84315;
    text-align: center;
    margin-bottom: 40px;
    font-weight: bold;
`;

const ColumnTitle = styled.h2`
    font-size: 28px;
    color: #d84315;
    margin-bottom: 20px;
    text-align: center;
`;

const InfoItem = styled.p`
    font-size: 18px;
    color: #5d4037;
    margin-bottom: 16px;
    text-align: center;
`;

const Form = styled.form`
    display: flex;
    flex-direction: column;
    gap: 20px;
`;

const Input = styled.input`
  padding: 14px;
  border: 1px solid #ccc;
  border-radius: 6px;
  font-size: 16px;
  transition: border-color 0.3s;

  &:focus {
    border-color: #d84315;
    outline: none;
  }
`;

const Textarea = styled.textarea`
  padding: 14px;
  border: 1px solid #ccc;
  border-radius: 6px;
  font-size: 16px;
  resize: vertical;
  min-height: 150px;
  transition: border-color 0.3s;

  &:focus {
    border-color: #d84315;
    outline: none;
  }
`;

const Button = styled.button`
  padding: 14px;
  background-color: #d84315;
  color: #fff;
  border: none;
  border-radius: 6px;
  font-size: 18px;
  cursor: pointer;
  transition: background-color 0.3s;

  &:hover {
    background-color: #bf360c;
  }
`;

const Contact = () => {
  return (
    <Wrapper>
      <Title>Contáctanos</Title>
      <Container>
        <InfoColumn>
          <ColumnTitle>Información de Contacto</ColumnTitle>
          <InfoItem>
            <strong>Teléfono:</strong> +34 987 654 321
          </InfoItem>
          <InfoItem>
            <strong>Email:</strong> contacto@infinitytech.es
          </InfoItem>
          <InfoItem>
            <strong>Dirección:</strong> Calle Imaginaria, 123, Madrid, España
          </InfoItem>
        </InfoColumn>
        <FormColumn>
          <ColumnTitle>Envíanos un Mensaje</ColumnTitle>
          <Form action="thanks/index.php" method="post">
            <Input type="text" name="nombre" placeholder="Tu nombre" required />
            <Input type="email" name="correo" placeholder="Tu correo electrónico" required />
            <Input type="text" name="asunto" placeholder="Asunto" required />
            <Textarea name="mensaje" placeholder="Escribe aquí tu mensaje..." required />
            <Button type="submit">Enviar Mensaje</Button>
          </Form>
        </FormColumn>
      </Container>
      <div style={{ marginTop: "40px" }}>
        <BloqueUbicacion />
      </div>
    </Wrapper>
  );
};

export default Contact;
