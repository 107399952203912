import React from "react";
import styled from "styled-components";

const HeaderSection = styled.section`
    background: linear-gradient(90deg, #fff8f0, #ffe0b2);
    padding: 40px 20px;
    color: #5d4037;
`;

const ContentContainer = styled.div`
    max-width: 800px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    gap: 40px;
`;

const VideoWrapper = styled.div`
    position: relative;
    width: 100%;
    padding-bottom: 56.25%; /* співвідношення 16:9 */
    margin-bottom: 15px;
    border-radius: 12px;
    overflow: hidden;
    box-shadow: 0 6px 10px rgba(0, 0, 0, 0.1);
`;

const StyledIframe = styled.iframe`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0;
`;

const Text = styled.p`
    font-size: 18px;
    line-height: 1.6;
    color: #5d4037;
    margin-top: 16px;
`;

const FormBlock = styled.div`
    background: rgba(255, 255, 255, 0.95);
    padding: 30px;
    border-radius: 12px;
    text-align: center;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
`;

const Form = styled.form`
    display: flex;
    flex-direction: column;
    gap: 20px;
`;

const Input = styled.input`
    padding: 12px;
    border: 1px solid #ccc;
    border-radius: 6px;
    font-size: 16px;
    transition: border-color 0.3s;

    &:focus {
        border-color: #bf360c;
        outline: none;
    }
`;

const Button = styled.button`
    padding: 14px;
    background-color: #bf360c;
    color: #fff;
    border: none;
    border-radius: 6px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s;

    &:hover {
        background-color: #d84315;
    }
`;

const AgreementContainer = styled.div`
    display: flex;
    align-items: center;
    font-size: 14px;
    color: #5d4037;
    margin-top: 10px;
    text-align: left;

    input[type="checkbox"] {
        margin-right: 8px;
    }

    a {
        color: #bf360c;
        text-decoration: none;
    }

    a:hover {
        text-decoration: underline;
    }
`;

const InfinityComponent = () => {
  const handleSubmit = (e) => {
    e.preventDefault();
    const formData = new FormData(e.target);

    // Відправка форми на сервер
    fetch("thanks/index.php", {
      method: "POST",
      body: formData,
    }).catch((error) => {
      console.error("Error submitting form:", error);
    });

    alert("Solicitud enviada");
    e.target.reset();
  };

  return (
    <HeaderSection>
      <ContentContainer>
        {/* Блок з відео та описом */}
        <div>
          <VideoWrapper>
            <StyledIframe
              src="https://www.youtube.com/embed/oAMv-dRF6Lg?si=SoTf3otwQS6rsLSA"
              title="Infinity Video"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            />
          </VideoWrapper>
          <Text>
            El infinito es un concepto que desafía la comprensión humana y despierta la imaginación. Representa la posibilidad de lo ilimitado, un viaje sin fin a través de ideas, sueños y descubrimientos. En nuestro proyecto, Infinity simboliza la unión de creatividad, tecnología y pasión, invitando a explorar nuevos horizontes y a romper barreras en la búsqueda de un futuro lleno de oportunidades.
          </Text>
        </div>
        {/* Блок з формою реєстрації */}
        <FormBlock>
          <h3 style={{ marginBottom: "20px", color: "#5d4037" }}>Registro</h3>
          <Form onSubmit={handleSubmit}>
            <Input
              type="text"
              id="nombre"
              name="nombre"
              placeholder="Ingrese su nombre"
              required
            />
            <Input
              type="text"
              id="apellido"
              name="apellido"
              placeholder="Ingrese su apellido"
              required
            />
            <Input
              type="email"
              id="email"
              name="email"
              placeholder="ejemplo@correo.com"
              required
            />
            <Input
              type="tel"
              id="telefono"
              name="telefono"
              placeholder="+34 600 000 000"
              required
            />
            <AgreementContainer>
              <input type="checkbox" id="terms" name="terms" required />
              <label htmlFor="terms">
                Acepto los{" "}
                <a href="/terms" rel="noopener noreferrer">
                  Términos y Condiciones
                </a>{" "}
                y la{" "}
                <a href="/cookies" rel="noopener noreferrer">
                  Política de Cookies
                </a>.
              </label>
            </AgreementContainer>
            <Button type="submit">Enviar solicitud</Button>
          </Form>
        </FormBlock>
      </ContentContainer>
    </HeaderSection>
  );
};

export default InfinityComponent;
