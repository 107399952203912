import React from "react";
import styled from "styled-components";

const TestimonialsContainer = styled.section`
    width: 100%;
    padding: 60px 20px;
    background: linear-gradient(90deg, #ffcc80, #ffb74d);
    box-sizing: border-box;
    color: #5d4037;
`;

const Title = styled.h2`
    text-align: center;
    font-size: 34px;
    margin-bottom: 50px;
    color: #bf360c;
    font-weight: bold;
`;

const TestimonialsGrid = styled.div`
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
    gap: 30px;
    justify-items: center;
`;

const TestimonialCard = styled.div`
    background: rgba(255, 255, 255, 0.95);
    padding: 30px 20px;
    border-radius: 12px;
    width: 100%;
    max-width: 320px;
    box-shadow: 0 6px 10px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease, box-shadow 0.3s ease;

    &:hover {
        transform: translateY(-8px);
        box-shadow: 0 8px 16px rgba(0, 0, 0, 0.15);
    }
`;

const ClientPhoto = styled.img`
    width: 70px;
    height: 70px;
    border-radius: 50%;
    object-fit: cover;
    margin: 0 auto 15px auto;
    display: block;
    border: 3px solid #ffb74d;
`;

const ClientName = styled.h3`
    font-size: 20px;
    margin-bottom: 5px;
    color: #bf360c;
    text-align: center;
`;

const ClientPosition = styled.p`
    font-size: 14px;
    margin-bottom: 15px;
    color: #6d4c41;
    text-align: center;
    opacity: 0.8;
`;

const ReviewText = styled.p`
    font-size: 16px;
    line-height: 1.5;
    color: #5d4037;
    text-align: center;
`;

const testimonialsData = [
  {
    photo: "test1.jpg",
    name: "María González",
    position: "CEO, Empresa A",
    review:
      "Infinity Tech España ha transformado la forma en que trabajamos. Su enfoque cálido y profesional ha impulsado nuestro éxito de manera significativa."
  },
  {
    photo: "test3.jpg",
    name: "Alejandro Pérez",
    position: "CTO, Empresa B",
    review:
      "La creatividad y el compromiso del equipo de Infinity hacen la diferencia. Sus soluciones innovadoras nos han permitido superar los retos del mercado."
  },
  {
    photo: "test2.jpg",
    name: "Lucía Rodríguez",
    position: "Directora de Marketing, Empresa C",
    review:
      "Trabajar con Infinity Tech España ha sido una experiencia enriquecedora. Su atención personalizada y experiencia han elevado nuestra estrategia de marketing."
  }
];

const Testimonials = () => {
  return (
    <TestimonialsContainer>
      <Title>Testimonios de Nuestros Clientes</Title>
      <TestimonialsGrid>
        {testimonialsData.map((item, index) => (
          <TestimonialCard key={index}>
            <ClientPhoto src={item.photo} alt={item.name} />
            <ClientName>{item.name}</ClientName>
            <ClientPosition>{item.position}</ClientPosition>
            <ReviewText>{item.review}</ReviewText>
          </TestimonialCard>
        ))}
      </TestimonialsGrid>
    </TestimonialsContainer>
  );
};

export default Testimonials;
