import React from "react";
import styled from "styled-components";

// Контейнер для блоку кейсів/портфоліо
const PortfolioContainer = styled.section`
    width: 100%;
    padding: 60px 20px;
    background: #fff8f0;
    box-sizing: border-box;
`;

// Заголовок блоку
const Title = styled.h2`
    text-align: center;
    font-size: 34px;
    margin-bottom: 40px;
    color: #bf360c;
    font-weight: bold;
`;

// Сітка для кейсів
const PortfolioGrid = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: 30px;
    justify-content: center;
`;

// Окрема картка кейсу
const PortfolioCard = styled.div`
    background-color: #fff;
    border-radius: 12px;
    overflow: hidden;
    box-shadow: 0 6px 10px rgba(0, 0, 0, 0.1);
    max-width: 320px;
    flex: 1;
    min-width: 260px;
    transition: transform 0.3s ease, box-shadow 0.3s ease;

    &:hover {
        transform: translateY(-8px);
        box-shadow: 0 8px 16px rgba(0, 0, 0, 0.15);
    }
`;

// Зображення кейсу
const CardImage = styled.img`
    width: 100%;
    height: 200px;
    object-fit: cover;
`;

// Контейнер для текстового контенту
const CardContent = styled.div`
    padding: 20px;
`;

// Назва кейсу
const CardTitle = styled.h3`
    font-size: 22px;
    margin-bottom: 12px;
    color: #bf360c;
`;

// Опис кейсу
const CardDescription = styled.p`
    font-size: 16px;
    color: #5d4037;
    line-height: 1.5;
`;

const portfolioData = [
  {
    image: "port1.jpg",
    title: "Innovación en Automatización",
    description:
      "Desarrollamos soluciones basadas en inteligencia artificial para optimizar procesos y elevar la productividad en diversas industrias."
  },
  {
    image: "port2.jpg",
    title: "Plataforma Digital Integral",
    description:
      "Una plataforma digital que fusiona tecnología y negocio para transformar la experiencia del usuario y generar valor."
  },
  {
    image: "port3.jpg",
    title: "Soluciones a Medida",
    description:
      "Diseñamos e implementamos soluciones personalizadas que responden a las necesidades específicas de cada cliente, impulsando su competitividad."
  },
  {
    image: "port4.jpg",
    title: "Estrategia Digital Transformadora",
    description:
      "Implementamos estrategias digitales innovadoras que fomentan el crecimiento sostenible y la transformación empresarial."
  }
];

const Portfolio = () => {
  return (
    <PortfolioContainer>
      <Title>Nuestros Proyectos Destacados</Title>
      <PortfolioGrid>
        {portfolioData.map((item, index) => (
          <PortfolioCard key={index}>
            <CardImage src={item.image} alt={item.title} />
            <CardContent>
              <CardTitle>{item.title}</CardTitle>
              <CardDescription>{item.description}</CardDescription>
            </CardContent>
          </PortfolioCard>
        ))}
      </PortfolioGrid>
    </PortfolioContainer>
  );
};

export default Portfolio;
