import React from "react";
import styled from "styled-components";

const ContenedorProyecto = styled.section`
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    padding: 60px 20px;
    justify-content: center;
    background: linear-gradient(90deg, #fff3e0, #ffe0b2);
    box-sizing: border-box;
`;

const ContenedorImagen = styled.div`
    flex: 1;
    min-width: 320px;
    max-width: 600px;
    position: relative;
    overflow: hidden;
    border-radius: 12px;
    box-shadow: 0 6px 10px rgba(0, 0, 0, 0.15);
`;

const Imagen = styled.img`
    width: 100%;
    height: auto;
    display: block;
`;

const ContenedorContenido = styled.div`
    flex: 1;
    min-width: 320px;
    max-width: 600px;
    background-color: #fff;
    border-radius: 12px;
    padding: 30px 30px 40px;
    box-shadow: 0 6px 10px rgba(0, 0, 0, 0.15);
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
`;

const TituloProyecto = styled.h2`
    font-size: 26px;
    color: #bf360c;
    margin-bottom: 20px;
    font-weight: bold;
    position: relative;
    z-index: 2;
`;

const DescripcionProyecto = styled.p`
  font-size: 18px;
  color: #5d4037;
  line-height: 1.6;
  margin-bottom: 0;
  position: relative;
  z-index: 2;
`;

// Додатковий декоративний елемент для виділення текстового блоку
const Decoracion = styled.div`
    content: "";
    position: absolute;
    top: 0;
    left: -20px;
    width: 5px;
    height: 100%;
    background-color: #bf360c;
    border-radius: 0 4px 4px 0;
    z-index: 1;
`;

const BloqueProyectoInfinity = () => {
  return (
    <ContenedorProyecto>
      <ContenedorImagen>
        <Imagen src="/infinity.jpg" alt="Proyecto Infinity" />
      </ContenedorImagen>
      <ContenedorContenido>
        <Decoracion />
        <TituloProyecto>Proyecto Infinity: Innovación sin Límites</TituloProyecto>
        <DescripcionProyecto>
          Descubre Infinity, donde la creatividad y la tecnología se unen para impulsar soluciones que rompen fronteras.
          Nuestro proyecto redefine el futuro al ofrecer herramientas disruptivas y estrategias innovadoras para transformar industrias.
          ¡Únete a la revolución Infinity y sé parte del cambio!
        </DescripcionProyecto>
      </ContenedorContenido>
    </ContenedorProyecto>
  );
};

export default BloqueProyectoInfinity;
