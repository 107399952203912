import React from "react";
import styled from "styled-components";

const FooterContainer = styled.footer`
  background: linear-gradient(90deg, #283593, #5c6bc0);
  color: #fff;
  padding: 60px 20px 40px;
  box-sizing: border-box;
  border-top: 2px solid rgba(255, 255, 255, 0.6);
`;

const FooterContent = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 40px;
`;

const Section = styled.div`
  flex: 1;
  min-width: 250px;
`;

const SectionTitle = styled.h3`
  font-size: 24px;
  margin-bottom: 15px;
  font-weight: 600;
  color: #ffeb3b;
`;

const Text = styled.p`
  font-size: 16px;
  margin-bottom: 8px;
  line-height: 1.5;
`;

const FooterLink = styled.a`
  color: #fff;
  text-decoration: none;
  font-size: 16px;
  display: block;
  margin-bottom: 8px;
  transition: color 0.3s;

  &:hover {
    color: #ffeb3b;
  }
`;

const FooterBottom = styled.div`
  text-align: center;
  border-top: 1px solid rgba(255, 255, 255, 0.5);
  padding-top: 20px;
  font-size: 14px;
  margin-top: 30px;
`;

const Footer = () => {
  return (
    <FooterContainer>
      <FooterContent>
        <Section>
          <SectionTitle>Infinity Tech España</SectionTitle>
          <Text>Dirección: Calle Gran Vía, 21, 28011 Madrid, España</Text>
          <Text>Teléfono: +34 912 345 222</Text>
          <Text>Email: info@infinitytech.es</Text>
        </Section>
        <Section>
          <SectionTitle>Enlaces</SectionTitle>
          <FooterLink href="/" target="_blank" rel="noopener noreferrer">
            Inicio
          </FooterLink>
          <FooterLink href="/about" target="_blank" rel="noopener noreferrer">
            Sobre nosotros
          </FooterLink>
          <FooterLink href="/contact" target="_blank" rel="noopener noreferrer">
            Contacto
          </FooterLink>
        </Section>
        <Section>
          <SectionTitle>Síguenos</SectionTitle>
          <FooterLink
            href="https://www.facebook.com/InfinityTechEspana"
            target="_blank"
            rel="noopener noreferrer"
          >
            Facebook
          </FooterLink>
          <FooterLink
            href="https://twitter.com/InfinityTechEs"
            target="_blank"
            rel="noopener noreferrer"
          >
            Twitter
          </FooterLink>
          <FooterLink
            href="https://www.linkedin.com/company/infinity-tech-espana"
            target="_blank"
            rel="noopener noreferrer"
          >
            LinkedIn
          </FooterLink>
          <FooterLink
            href="https://www.instagram.com/infinitytechespana"
            target="_blank"
            rel="noopener noreferrer"
          >
            Instagram
          </FooterLink>
        </Section>
      </FooterContent>
      <FooterBottom>
        &copy; {new Date().getFullYear()} Infinity Tech España. Todos los derechos reservados.
      </FooterBottom>
    </FooterContainer>
  );
};

export default Footer;
