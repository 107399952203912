import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import styled from "styled-components";

const HeaderWrapper = styled.header`
    position: fixed;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 100;
    background: ${({ isScrolled }) =>
            isScrolled ? "linear-gradient(90deg, #283593, #5c6bc0)" : "#5c6bc0"};
    box-shadow: ${({ isScrolled }) =>
            isScrolled ? "0 2px 8px rgba(0, 0, 0, 0.15)" : "none"};
    transition: background 0.3s, box-shadow 0.3s;
    padding: 20px 30px;
`;

const HeaderContainer = styled.div`
    max-width: 1200px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
`;

const LogoLink = styled(Link)`
    display: flex;
    align-items: center;
    text-decoration: none;
`;

const LogoIcon = styled.div`
    width: 48px;
    height: 48px;
    background-color: #ffeb3b; /* Яскравий жовтий акцент */
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 10px;
`;

const LogoText = styled.span`
    font-size: 26px;
    font-weight: 600;
    color: #fff;
`;

const Nav = styled.nav`
    display: flex;
    align-items: center;
    gap: 30px;
`;

const NavLink = styled(Link)`
    text-decoration: none;
    font-size: 18px;
    color: #fff;
    font-weight: 500;
    transition: color 0.2s;

    &:hover {
        color: #ffeb3b;
    }
`;

const Hamburger = styled.div`
    display: none;
    flex-direction: column;
    cursor: pointer;
    @media (max-width: 768px) {
        display: flex;
    }
`;

const Bar = styled.span`
    height: 3px;
    width: 24px;
    background-color: #fff;
    margin: 3px 0;
`;

const MobileMenu = styled.div`
    position: absolute;
    top: 100%;
    right: 30px;
    background: #283593;
    border: 1px solid #5c6bc0;
    border-radius: 4px;
    overflow: hidden;
    display: ${({ open }) => (open ? "block" : "none")};

    @media (min-width: 769px) {
        display: none;
    }
`;

const MobileMenuItem = styled(Link)`
    display: block;
    padding: 12px 16px;
    text-decoration: none;
    color: #fff;
    border-bottom: 1px solid #5c6bc0;
    transition: background-color 0.2s;

    &:hover {
        background-color: #5c6bc0;
    }

    &:last-child {
        border-bottom: none;
    }
`;

const Header = () => {
  const [isScrolled, setIsScrolled] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const location = useLocation();

  useEffect(() => {
    const onScroll = () => {
      setIsScrolled(window.scrollY > 50);
    };
    window.addEventListener("scroll", onScroll);
    return () => window.removeEventListener("scroll", onScroll);
  }, []);

  const handleLinkClick = () => {
    setIsMenuOpen(false);
  };

  return (
    <HeaderWrapper isScrolled={isScrolled}>
      <HeaderContainer>
        <LogoLink to="/">
          <LogoIcon>
            <span style={{ color: "#333", fontSize: "24px", fontWeight: "bold" }}>∞</span>
          </LogoIcon>
          <LogoText>InfinityQ</LogoText>
        </LogoLink>
        <Nav>
          <NavLink to="/" onClick={handleLinkClick}>
            Inicio
          </NavLink>
          <NavLink to="/about" onClick={handleLinkClick}>
            Sobre nosotros
          </NavLink>
          <NavLink to="/contact" onClick={handleLinkClick}>
            Contacto
          </NavLink>
        </Nav>
        <Hamburger onClick={() => setIsMenuOpen(!isMenuOpen)}>
          <Bar />
          <Bar />
          <Bar />
        </Hamburger>
        <MobileMenu open={isMenuOpen}>
          <MobileMenuItem to="/" onClick={handleLinkClick}>
            Inicio
          </MobileMenuItem>
          <MobileMenuItem to="/about" onClick={handleLinkClick}>
            Sobre nosotros
          </MobileMenuItem>
          <MobileMenuItem to="/contact" onClick={handleLinkClick}>
            Contacto
          </MobileMenuItem>
        </MobileMenu>
      </HeaderContainer>
    </HeaderWrapper>
  );
};

export default Header;
